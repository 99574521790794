import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const Changelog = ({ isOpen, onClose }) => {
  const modalRef = useRef();

  const navigate = useNavigate();

  const handleLanguageDatabaseClick = () => {
    onClose();
    navigate('/languages');
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (isOpen && modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-10 flex justify-center items-center p-4">
      <div
        className="rounded-lg shadow-lg bg-gradient-to-br from-purple-700 via-blue-600 to-teal-500 text-white w-full max-w-3xl mx-auto opacity-95 relative"
        style={{
          backdropFilter: 'blur(10px)',
          maxHeight: '90vh',
        }}
        ref={modalRef}
      >
        <button
          className="absolute top-2 right-2 text-xl font-bold text-white"
          onClick={onClose}
        >
          &times;
        </button>
        <div
          className="overflow-y-auto p-6"
          style={{
            maxHeight: '85vh',
          }}
        >
          <h2 className="text-3xl font-bold mb-6 text-center">Changelog</h2>

          <p className="mb-4 font-bold text-lg flex justify-center items-center">
            v0.6{' '}
            <span className="text-xs bg-black bg-opacity-70 text-gray-300 px-1 py-0.5 rounded-md font-light ml-2">
              09/02/2025
            </span>
          </p>
          <ul className="list-disc list-inside space-y-2 ml-4 mb-6">
            <li>
              Introduced comprehensive user account system:
              <div className="mt-1 p-2 rounded-lg bg-gray-800 text-white">
                <p className="italic text-sm">Email and Google authentication.</p>
                <p className="italic text-sm">Guest play option for quick access.</p>
                <p className="italic text-sm">Email verification system.</p>
              </div>
            </li>
            <li>
              New personalized dashboard for registered users featuring:
              <div className="mt-1 p-2 rounded-lg bg-gray-800 text-white">
                <p className="italic text-sm">Recent games history.</p>
                <p className="italic text-sm">Total games played statistics.</p>
                <p className="italic text-sm">Highest and average scores.</p>
                <p className="italic text-sm">Profile customization options.</p>
              </div>
            </li>
            <li>
              Enhanced user experience:
              <div className="mt-1 p-2 rounded-lg bg-gray-800 text-white">
                <p className="italic text-sm">Intuitive onboarding process.</p>
                <p className="italic text-sm">Seamless guest-to-account transition.</p>
                <p className="italic text-sm">Newsletter subscription system.</p>
              </div>
            </li>
            <li>Performance improvements and bug fixes.</li>
          </ul>

          <p className="mb-4 font-bold text-lg flex justify-center items-center">
            v0.5{' '}
            <span className="text-xs bg-black bg-opacity-70 text-gray-300 px-1 py-0.5 rounded-md font-light ml-2">
              22/01/2025
            </span>
          </p>
          <ul className="list-disc list-inside space-y-2 ml-4 mb-6">
            <li>Small fixes on difficulty levels during the first few rounds.</li>
            <li>12 new audio clips added. You can check our current audio database <button onClick={handleLanguageDatabaseClick} className="font-semibold text-green-300 hover:text-green-500 transition-colors duration-200">here</button>.</li>
            <li>Small UI changes on Leaderboard and Languages pages.</li>
            <li>Now you can search for a specific language or country on the Languages page.</li>
          </ul>

          <p className="mb-4 font-bold text-lg flex justify-center items-center">
            v0.4b{' '}
            <span className="text-xs bg-black bg-opacity-70 text-gray-300 px-1 py-0.5 rounded-md font-light ml-2">
              12/12/2024
            </span>
          </p>
          <ul className="list-disc list-inside space-y-2 ml-4 mb-6">
            <li>Disabled multiplayer due to some matchmaking errors.</li>
            <li>Further UI/UX changes on solo play and leaderboard.</li>
            <li>United Kingdom region divided into Scotland, England, Wales, Northern Ireland.</li>
          </ul>

          <p className="mb-4 font-bold text-lg flex justify-center items-center">
            v0.4{' '}
            <span className="text-xs bg-black bg-opacity-70 text-gray-300 px-1 py-0.5 rounded-md font-light ml-2">
              07/12/2024
            </span>
          </p>
          <ul className="list-disc list-inside space-y-2 ml-4 mb-6">
            <li>
              Implemented beta version of multiplayer 1v1 mode in real-time, with a waiting room showing number of active players. 
              <br />1v1 matches, 10 rounds, highest score wins.
              <div className="mt-1 p-2 rounded-lg bg-gray-800 text-white">
                <p className="italic text-sm">Real-time multiplayer with competitive gameplay.</p>
                <p className="italic text-sm">Waiting room to match players.</p>
              </div>
            </li>
            <li>Implemented UI/UX changes, including new animations.</li>
          </ul>

          <p className="mb-4 font-bold text-lg flex justify-center items-center">
            v0.3b{' '}
            <span className="text-xs bg-black bg-opacity-70 text-gray-300 px-1 py-0.5 rounded-md font-light ml-2">
              04/12/2024
            </span>
          </p>
          <ul className="list-disc list-inside space-y-2 ml-4 mb-6">
            <li>
              Added{' '}
              <a
                href="https://buymeacoffee.com/langguesser"
                target="_blank"
                rel="noopener noreferrer"
                className="italic font-semibold text-yellow-300 hover:text-yellow-500 transition-colors duration-200"
              >
                Buy Me a Coffee
              </a>{' '}
              link to the main screen.
            </li>
            <li>Randomized background system that changes hourly based on CET time.</li>
            <li>Languages page interface rework.</li>
            <li>Small roadmap updates.</li>
          </ul>

          <p className="mb-4 font-bold text-lg flex justify-center items-center">
            v0.3{' '}
            <span className="text-xs bg-black bg-opacity-70 text-gray-300 px-1 py-0.5 rounded-md font-light ml-2">
              02/12/2024
            </span>
          </p>
          <ul className="list-disc list-inside space-y-2 ml-4 mb-6">
            <li>Implemented progressive difficulty scaling in the game.</li>
            <li>Made gameplay easier on the first rounds to encourage longer sessions and improve user retention.</li>
          </ul>

          <p className="mb-4 font-bold text-lg flex justify-center items-center">
            v0.2{' '}
            <span className="text-xs bg-black bg-opacity-70 text-gray-300 px-1 py-0.5 rounded-md font-light ml-2">
              29/11/2024
            </span>
          </p>
          <ul className="list-disc list-inside space-y-2 ml-4 mb-6">
            <li>Complete UI overhaul for a more modern look.</li>
            <li>Randomized logo system that changes hourly based on CET time.</li>
            <li>Improved mobile responsiveness, included dynamic map resizing.</li>
          </ul>

          <p className="mb-4 font-bold text-lg flex justify-center items-center">
            v0.1b{' '}
            <span className="text-xs bg-black bg-opacity-70 text-gray-300 px-1 py-0.5 rounded-md font-light ml-2">
              28/02/2024
            </span>
          </p>
          <ul className="list-disc list-inside space-y-2 ml-4 mb-6">
            <li>Added 32 more audio clips.</li>
            <li>
              Scoring system implemented:
              <div className="mt-2 p-4 rounded-lg bg-gray-800 text-white">
                <p className="italic">
                  <span className="font-bold">Base points:</span> 100
                </p>
                <p className="italic">
                  <span className="font-bold">Time bonus:</span> TimeLeft / 30 × 100
                </p>
                <p className="italic">
                  <span className="font-bold">Streak bonus:</span> 10% increase per consecutive correct answer
                </p>
              </div>
            </li>
            <li>Minor UI tweaks.</li>
            <li>
              Added <b>changelog</b> page, along with a languages database page.
            </li>
          </ul>

          <p className="mb-4 font-bold text-lg flex justify-center items-center">
            v0.1{' '}
            <span className="text-xs bg-black bg-opacity-70 text-gray-300 px-1 py-0.5 rounded-md font-light ml-2">
              21/02/2024
            </span>
          </p>
          <ul className="list-disc list-inside space-y-2 ml-4">
            <li>Launched with 56 audio clips.</li>
            <li>Skeleton code for future scoring system and different difficulties.</li>
            <li>Leaderboard showing the top 10 players.</li>
          </ul>

          <div className="text-center mt-6">
            <button
              className="font-bold text-green-300 hover:text-green-500 focus:outline-none"
              onClick={handleLanguageDatabaseClick}
            >
              Current languages database
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Changelog;