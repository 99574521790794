import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NicknameInput = ({ onNicknameChange, isError, setIsError, shake, setShake }) => {
  const navigate = useNavigate();
  const [nickname, setNickname] = useState('');

  useEffect(() => {
    const skipNicknameInput = localStorage.getItem('skipNicknameInput');
    const existingNickname = localStorage.getItem('nickname');
    
    if (skipNicknameInput === 'true' && existingNickname) {
      localStorage.removeItem('skipNicknameInput');
      navigate('/game');
    }
  }, [navigate]);

  useEffect(() => {
    if (shake) {
      const timer = setTimeout(() => setShake(false), 500);
      return () => clearTimeout(timer);
    }
  }, [shake]);

  const handleNicknameChange = (e) => {
    const value = e.target.value;
    setNickname(value);
    setIsError(false);
    onNicknameChange(value);
  };

  return (
    <div className="flex flex-col items-center w-full">
      <style>
        {`
          @keyframes shake {
            0%, 100% { transform: translateX(0); }
            10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
            20%, 40%, 60%, 80% { transform: translateX(5px); }
          }
          .shake {
            animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
          }
        `}
      </style>
      <div className={`w-full relative mb-4 ${shake ? 'shake' : ''}`}>
        <input
          type="text"
          className={`px-4 py-2 rounded shadow-lg text-black w-full text-center focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
            isError ? 'border-2 border-red-500' : ''
          }`}
          placeholder="Enter your nickname"
          value={nickname}
          onChange={handleNicknameChange}
          maxLength={20}
        />
        {isError && (
          <p className="text-red-500 text-sm text-center mt-1">!!!</p>
        )}
      </div>
    </div>
  );
};

export default NicknameInput;
