import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Modal } from 'antd';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import WelcomeModal from './components/WelcomeModal';

// Components
import Game from './components/Game';
import Multiplayer from './components/Multiplayer';
import Leaderboard from './components/Leaderboard';
import Languages from './components/Languages';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import UserMenu from './components/UserMenu';
import NicknameInput from './components/NicknameInput';
import Instructions from './components/Instructions';
import Changelog from './components/Changelog';
import Roadmap from './components/Roadmap';
import EmailVerification from './components/EmailVerification';
import AdminDashboard from './components/AdminDashboard';

function App() {
  const [showInstructions, setShowInstructions] = useState(false);
  const [showChangelog, setShowChangelog] = useState(false);
  const [showRoadmap, setShowRoadmap] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [nickname, setNickname] = useState('');
  const [shake, setShake] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMaintenanceModalVisible, setIsMaintenanceModalVisible] = useState(false);

  // Background image rotation
  const [currentBackground, setCurrentBackground] = useState('/images/background.webp');
  const [currentLogo, setCurrentLogo] = useState('/images/logo3.png');

  // List of logos and backgrounds
  const logos = [
    '/images/logo3.png',
    '/images/logo4.png',
    '/images/logo5.png',
    '/images/logo6.png',
  ];

  const backgrounds = [
    '/images/bg.png',
    '/images/bg2.png',
    '/images/bg3.png',
    '/images/bg4.png',
  ];

  const determineAssetsByHour = () => {
    const currentCETHour = new Date().toLocaleString('en-US', {
      timeZone: 'Europe/Paris',
      hour: '2-digit',
      hour12: false,
    });

    const index = parseInt(currentCETHour, 10) % logos.length;
    setCurrentLogo(logos[index]);
    setCurrentBackground(backgrounds[index]);
  };

  useEffect(() => {
    determineAssetsByHour();
    const intervalId = setInterval(determineAssetsByHour, 3600000); // Update every hour

    return () => clearInterval(intervalId);
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <AuthProvider>
        <div 
          className="min-h-screen bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${currentBackground})`,
          }}
        >
          {/* User Menu - Top Right */}
          <div className="absolute top-4 right-4 z-50">
            <UserMenu />
          </div>

          {location.pathname === '/' && <WelcomeModal />}

          {/* Main Content */}
          <Routes>
            <Route path="/login" element={<Login logo={currentLogo} />} />
            <Route path="/register" element={<Register logo={currentLogo} />} />
            <Route path="/verify-email/:token" element={<EmailVerification />} />
            <Route path="/languages" element={<Languages />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/game" element={<Game />} />
            <Route path="/multiplayer" element={<Multiplayer />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute adminOnly={true}>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/"
              element={
                <div className="min-h-screen flex flex-col items-center justify-center p-4">
                  <div className="w-full max-w-md p-4">
                    {/* Homepage Card */}
                    <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
                      <div className="flex justify-center mb-2">
                        <img src={currentLogo} alt="Logo" className="w-32 h-32" />
                      </div>
                      <h1 className="text-4xl font-bold text-center mb-2">LangGuesser</h1>
                      <p className="text-center mb-6">
                        Guess the language's country by its accent
                      </p>
                      {!localStorage.getItem('token') ? (
                        <>
                          <NicknameInput 
                            onNicknameChange={(value) => setNickname(value)} 
                            isError={isError}
                            setIsError={setIsError}
                            shake={shake}
                            setShake={setShake}
                          />
                          <button
                            type="submit"
                            onClick={() => {
                              if (!nickname?.trim()) {
                                setIsError(true);
                                setShake(true);
                                return;
                              }
                              setIsModalVisible(true);
                            }}
                            className="p-[3px] relative mb-4 flex items-center justify-center w-full"
                          >
                            <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg w-full" />
                            <div className="px-8 py-2 bg-black rounded-[6px] relative group transition duration-200 text-white hover:bg-transparent flex items-center justify-center w-full">
                              Play Now
                            </div>
                          </button>
                          <div className="flex justify-center space-x-4 text-white/80 mt-2">
                            <button
                              onClick={() => {
                                if (!nickname?.trim()) {
                                  setIsError(true);
                                  setShake(true);
                                  return;
                                }
                                localStorage.setItem('nickname', nickname);
                                localStorage.setItem('gameMode', 'solo');
                                navigate('/game');
                              }}
                              className="text-white hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-purple-400 hover:to-pink-400 transition-all duration-300 transform hover:scale-110"
                            >
                              Solo Play
                            </button>
                            <span>|</span>
                            <button
                              onClick={() => {
                                if (!nickname?.trim()) {
                                  setIsError(true);
                                  setShake(true);
                                  return;
                                }
                                localStorage.setItem('nickname', nickname);
                                localStorage.setItem('gameMode', 'multiplayer');
                                setIsMaintenanceModalVisible(true);
                              }}
                              className="text-white hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-purple-400 hover:to-pink-400 transition-all duration-300 transform hover:scale-110"
                            >
                              Multiplayer
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => setIsModalVisible(true)}
                            className="p-[3px] relative mb-4 flex items-center justify-center w-full"
                          >
                            <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-lg w-full" />
                            <div className="px-8 py-2 bg-black rounded-[6px] relative group transition duration-200 text-white hover:bg-transparent flex items-center justify-center w-full">
                              Play Now
                            </div>
                          </button>
                          <div className="flex justify-center space-x-4 text-white/80 mt-2">
                            <button
                              onClick={() => {
                                localStorage.setItem('gameMode', 'solo');
                                navigate('/game');
                              }}
                              className="text-white hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-purple-400 hover:to-pink-400 transition-all duration-300 transform hover:scale-110"
                            >
                              Solo Play
                            </button>
                            <span>|</span>
                            <button
                              onClick={() => {
                                localStorage.setItem('gameMode', 'multiplayer');
                                setIsMaintenanceModalVisible(true);
                              }}
                              className="text-white hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-purple-400 hover:to-pink-400 transition-all duration-300 transform hover:scale-110"
                            >
                              Multiplayer
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Footer Links - Outside the card */}
                  <div className="text-center mt-6">
                    <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4 text-blue-200">
                      <button
                        className="focus:outline-none hover:text-blue-400"
                        onClick={() => setShowInstructions(true)}
                      >
                        How to Play
                      </button>
                      <span className="hidden sm:inline text-white">|</span>
                      <button
                        className="focus:outline-none hover:text-blue-400"
                        onClick={() => setShowChangelog(true)}
                      >
                        Changelog
                      </button>
                      <span className="hidden sm:inline text-white">|</span>
                      <button
                        className="focus:outline-none hover:text-blue-400"
                        onClick={() => setShowRoadmap(true)}
                      >
                        Roadmap
                      </button>
                    </div>
                  </div>

                  {/* Game Mode Modal */}
                  <Modal
                    title={
                      <div className="text-center font-bold text-lg">
                        Choose Game Mode
                      </div>
                    }
                    open={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={null}
                    centered
                    className="game-mode-modal"
                  >
                    <div className="flex flex-col space-y-4 p-4">
                      <button
                        onClick={() => {
                          if (!localStorage.getItem('token') && !nickname?.trim()) {
                            setIsError(true);
                            setShake(true);
                            setIsModalVisible(false);
                            return;
                          }
                          if (!localStorage.getItem('token')) {
                            localStorage.setItem('nickname', nickname);
                          }
                          localStorage.setItem('gameMode', 'solo');
                          setIsModalVisible(false);
                          navigate('/game');
                        }}
                        className="w-full p-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded hover:from-purple-600 hover:to-pink-600 transition-all duration-300 transform hover:scale-105"
                      >
                        Solo Play
                      </button>
                      <button
                        onClick={() => {
                          if (!localStorage.getItem('token') && !nickname?.trim()) {
                            setIsError(true);
                            setShake(true);
                            setIsModalVisible(false);
                            return;
                          }
                          if (!localStorage.getItem('token')) {
                            localStorage.setItem('nickname', nickname);
                          }
                          localStorage.setItem('gameMode', 'multiplayer');
                          setIsModalVisible(false);
                          setIsMaintenanceModalVisible(true);
                        }}
                        className="w-full p-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded hover:from-purple-600 hover:to-pink-600 transition-all duration-300 transform hover:scale-105"
                      >
                        1v1 Multiplayer
                      </button>
                    </div>
                  </Modal>

                  {/* Maintenance Modal */}
                  <Modal
                    title={
                      <div className="text-center font-bold text-lg text-yellow-500">
                        🛠️ Under Maintenance
                      </div>
                    }
                    open={isMaintenanceModalVisible}
                    onCancel={() => setIsMaintenanceModalVisible(false)}
                    footer={[
                      <button
                        key="back"
                        onClick={() => setIsMaintenanceModalVisible(false)}
                        className="w-full p-2 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded hover:from-purple-600 hover:to-pink-600 transition-all duration-300"
                      >
                        Back to Menu
                      </button>
                    ]}
                    centered
                    className="maintenance-modal"
                  >
                    <div className="text-center p-4">
                      <p className="text-lg mb-4">
                        The multiplayer mode is currently under maintenance. Please try again later!
                      </p>
                      <p className="text-gray-500">
                        In the meantime, you can enjoy our Solo Play mode to test your language guessing skills!
                      </p>
                    </div>
                  </Modal>

                  {/* Other Modals */}
                  <Instructions isOpen={showInstructions} onClose={() => setShowInstructions(false)} />
                  <Changelog isOpen={showChangelog} onClose={() => setShowChangelog(false)} />
                  <Roadmap isOpen={showRoadmap} onClose={() => setShowRoadmap(false)} />

                  {/* "Buy Me a Coffee" Link */}
                  <div className="text-center mt-5">
                    <a
                      href="https://buymeacoffee.com/langguesser"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-yellow-300 hover:text-yellow-500"
                    >
                      Buy me a coffee ☕
                    </a>
                  </div>
                </div>
              }
            />
          </Routes>
        </div>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
