import React, { useState, useEffect } from 'react';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

export default function Dashboard() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [showEditNickname, setShowEditNickname] = useState(false);
  const [nickname, setNickname] = useState(currentUser?.nickname || '');
  const [email, setEmail] = useState(currentUser?.email || '');
  const [createdAt, setCreatedAt] = useState(currentUser?.createdAt ? new Date(currentUser.createdAt) : null);
  const [isEmailVerified, setIsEmailVerified] = useState(currentUser?.isEmailVerified || false);
  const [preferences, setPreferences] = useState(currentUser?.preferences || {});
  const [error, setError] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [gameStats, setGameStats] = useState({
    soloGames: 0,
    multiplayerGames: 0,
    averageScore: 0,
    highestScore: 0,
    recentGames: []
  });

  useEffect(() => {
    loadUserProfile();
  }, []);

  const loadUserProfile = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const { data } = response.data;
      setNickname(data.nickname);
      setEmail(data.email);
      setCreatedAt(new Date(data.createdAt));
      setIsEmailVerified(data.isEmailVerified);
      setPreferences(data.preferences);
      setGameStats(data.gameStats || {
        soloGames: 0,
        multiplayerGames: 0,
        averageScore: 0,
        highestScore: 0,
        recentGames: []
      });
    } catch (error) {
      console.error('Error loading profile:', error);
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/auth/profile`,
        { nickname },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const { data } = response.data;
      setNickname(data.nickname);
      setShowEditNickname(false);
      setUpdateSuccess(true);
      setTimeout(() => setUpdateSuccess(false), 3000);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
      setTimeout(() => setError(''), 3000);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch {
      setError('Failed to log out');
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="min-h-screen bg-cover bg-center bg-no-repeat">
      {/* Header */}
      <header className="bg-gradient-to-r from-purple-600 via-blue-500 to-teal-400 shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold text-white hover:text-gray-200 transition-colors">
              LangGuesser
            </Link>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Profile Section */}
          <div className="space-y-6">
            {/* Profile Card */}
            <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
              <div className="flex items-center space-x-4">
                <div className="h-14 w-14 rounded-full bg-white/20 flex items-center justify-center text-2xl">
                  {nickname ? nickname[0].toUpperCase() : email[0].toUpperCase()}
                </div>
                <div className="flex-1">
                  {showEditNickname ? (
                    <form onSubmit={handleUpdateProfile} className="space-y-2">
                      <input
                        type="text"
                        value={nickname}
                        onChange={(e) => setNickname(e.target.value)}
                        className="w-full px-3 py-2 border rounded-md text-gray-900"
                        placeholder="Enter new nickname"
                      />
                      <div className="flex space-x-2">
                        <button
                          type="submit"
                          className="px-3 py-1 bg-green-500 text-white rounded-md hover:bg-green-600"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={() => setShowEditNickname(false)}
                          className="px-3 py-1 bg-gray-600 text-white rounded-md hover:bg-gray-700"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  ) : (
                    <>
                      <h2 className="text-xl font-semibold">
                        {nickname || email}
                      </h2>
                      <button
                        onClick={() => setShowEditNickname(true)}
                        className="text-sm text-white/80 hover:text-white"
                      >
                        Edit nickname
                      </button>
                    </>
                  )}
                </div>
              </div>
              <p className="text-white/80 text-sm mt-4">
                Member since: {formatDate(createdAt)}
              </p>
            </div>

            {/* Stats Card */}
            <div className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6">
              <h2 className="text-lg font-semibold mb-4">Your Stats</h2>
              <div className="space-y-3">
                <div>
                  <p className="text-white/80">Games Played</p>
                  <p className="text-2xl font-semibold">
                    {gameStats.soloGames || 0}
                  </p>
                </div>
                <div>
                  <p className="text-white/80">Multiplayer Games</p>
                  <p className="text-2xl font-semibold">
                    {gameStats.multiplayerGames || 0}
                  </p>
                </div>
                <div>
                  <p className="text-white/80">Average Score</p>
                  <p className="text-2xl font-semibold">
                    {gameStats.averageScore || 0}
                  </p>
                </div>
                <div>
                  <p className="text-white/80">Highest Score</p>
                  <p className="text-2xl font-semibold">
                    {gameStats.highestScore || 0}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Navigation Cards */}
          <div className="lg:col-span-2">
            {error && (
              <div className="mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <span className="block sm:inline">{error}</span>
              </div>
            )}
            {updateSuccess && (
              <div className="mb-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                <span className="block sm:inline">Profile updated successfully!</span>
              </div>
            )}

            {/* Recent Games */}
            <div className="rounded-lg shadow-lg bg-white p-6 mb-6">
              <h2 className="text-xl font-semibold mb-4">Recent Games</h2>
              {gameStats.recentGames && gameStats.recentGames.length > 0 ? (
                <div className="space-y-4">
                  {gameStats.recentGames.map((game, index) => (
                    <div key={index} className="flex justify-between items-center border-b pb-2">
                      <div>
                        <p className="font-medium">{game.gameMode}</p>
                        <p className="text-sm text-gray-500">{formatDate(game.playedAt)}</p>
                      </div>
                      <div className="text-right">
                        <p className="font-semibold">{game.score} points</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500">No games played yet</p>
              )}
            </div>

            {/* Navigation Links */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {[
                {
                  title: 'Solo Play',
                  description: 'Test your language guessing skills',
                  icon: '🎮',
                  path: '/game'
                },
                {
                  title: 'Multiplayer',
                  description: 'Compete with other players',
                  icon: '👥',
                  path: '/multiplayer'
                },
                {
                  title: 'Leaderboard',
                  description: 'View top players',
                  icon: '🏆',
                  path: '/leaderboard'
                }
              ].map((item, index) => (
                <Link
                  key={index}
                  to={item.path}
                  className="rounded-lg shadow-lg bg-gradient-to-br from-purple-600 via-blue-500 to-teal-400 text-white p-6 hover:shadow-xl transition-shadow"
                >
                  <div className="flex items-center space-x-4">
                    <span className="text-3xl">{item.icon}</span>
                    <div className="flex-1 text-left">
                      <h3 className="text-lg font-semibold">{item.title}</h3>
                      <p className="text-white/80">{item.description}</p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
