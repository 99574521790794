import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

export default function AdminDashboard() {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { currentUser } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setError('');

    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('Not authenticated');

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/newsletter`,
        { subject, content },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setMessage(response.data.data.message);
      setSubject('');
      setContent('');
    } catch (error) {
      console.error('Error sending newsletter:', error);
      if (error.response?.status === 403) {
        setError('You do not have admin privileges to send newsletters');
      } else if (error.response?.status === 401) {
        setError('Please log in again to continue');
      } else {
        setError(error.response?.data?.error || 'Failed to send newsletter');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full max-w-2xl p-6">
        <div className="bg-white/10 backdrop-blur-md rounded-xl shadow-2xl p-8">
          <h2 className="text-3xl font-bold text-center mb-8 text-white">Admin Dashboard</h2>

          <div className="mb-8">
            <h3 className="text-xl font-semibold text-white mb-4">Send Newsletter</h3>
            
            {error && (
              <div className="bg-red-500/20 backdrop-blur-sm border border-red-500/30 text-white rounded-lg p-4 mb-4">
                {error}
              </div>
            )}
            
            {message && (
              <div className="bg-green-500/20 backdrop-blur-sm border border-green-500/30 text-white rounded-lg p-4 mb-4">
                {message}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-white text-sm font-medium mb-2">
                  Subject
                </label>
                <input
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-md text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30"
                  placeholder="Newsletter Subject"
                  required
                />
              </div>

              <div>
                <label className="block text-white text-sm font-medium mb-2">
                  Content (HTML)
                </label>
                <textarea
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  rows="10"
                  className="w-full px-3 py-2 bg-white/10 border border-white/20 rounded-md text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/30 font-mono"
                  placeholder="Newsletter Content (HTML supported)"
                  required
                />
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? 'Sending...' : 'Send Newsletter'}
              </button>
            </form>
          </div>

          <div className="text-white/70 text-sm">
            <h4 className="font-semibold mb-2">Quick Tips:</h4>
            <ul className="list-disc list-inside space-y-1">
              <li>Use HTML tags to format your content</li>
              <li>Test the newsletter by sending to a small group first</li>
              <li>Include a clear call-to-action</li>
              <li>Preview your content before sending</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
