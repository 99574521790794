import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const WelcomeModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [dontShow, setDontShow] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    // Don't show modal if user is logged in or if they've chosen not to see it
    const hasShownModal = localStorage.getItem('welcomeModalShown');
    if (!hasShownModal && !currentUser) {
      setIsOpen(true);
      // Small delay to trigger the animation
      setTimeout(() => setIsVisible(true), 50);
    }
  }, [currentUser]);

  const handleClose = useCallback(() => {
    setIsVisible(false);
    // Wait for animation to finish before removing from DOM
    setTimeout(() => setIsOpen(false), 300);
    if (dontShow) {
      localStorage.setItem('welcomeModalShown', 'true');
    }
  }, [dontShow]);

  const handleModalClick = useCallback((e) => {
    e.stopPropagation(); // Prevent click from reaching the backdrop
  }, []);

  if (!isOpen) return null;

  return (
    // Outer container
    <div className="fixed inset-0 z-50 overflow-y-auto" onClick={handleClose}>
      {/* Semi-transparent backdrop */}
      <div 
        className={`fixed inset-0 bg-black transition-opacity duration-300 ease-in-out ${
          isVisible ? 'bg-opacity-75' : 'bg-opacity-0'
        }`}
      />

      {/* Modal container */}
      <div className="relative z-50 flex min-h-full items-center justify-center p-4">
        {/* Modal content */}
        <div 
          onClick={handleModalClick}
          className={`relative transform overflow-hidden rounded-lg bg-gradient-to-br from-gray-900 to-gray-800 border border-gray-700 px-4 pb-4 pt-5 text-left shadow-xl transition-all duration-300 ease-in-out sm:my-8 sm:w-full sm:max-w-lg sm:p-6 ${
            isVisible 
              ? 'opacity-100 translate-y-0' 
              : 'opacity-0 translate-y-4'
          }`}
        >
          {/* Close button */}
          <div className="absolute right-0 top-0 pr-4 pt-4">
            <button
              type="button"
              onClick={handleClose}
              className="rounded-md text-gray-400 hover:text-gray-300 focus:outline-none transition-colors duration-200"
            >
              <span className="sr-only">Close</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Content */}
          <div className="text-center">
            <h3 className="text-2xl font-bold leading-6 text-white mb-6 mt-2">
              Welcome to LangGuesser! 🌎
            </h3>
            <div className="mt-4">
              <p className="text-gray-300 mb-6">
                Experience the best of LangGuesser by signing up or logging in. Save your progress, compete with friends, and unlock special features!
              </p>
              
              {/* Primary Actions */}
              <div className="mt-6 flex flex-col gap-3">
                <Link
                  to="/register"
                  className="inline-flex justify-center rounded-md bg-indigo-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-colors duration-200"
                  onClick={handleClose}
                >
                  Sign Up
                </Link>
                <Link
                  to="/login"
                  className="inline-flex justify-center rounded-md bg-gray-700 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 transition-colors duration-200"
                  onClick={handleClose}
                >
                  Log In
                </Link>
              </div>

              {/* Guest Option */}
              <div className="mt-6">
                <p className="text-gray-400 mb-2 text-sm font-light">
                  <i>Prefer to play as a guest? No problem, dive in and enjoy the game now!</i>
                </p>
                <button
                  onClick={handleClose}
                  className="text-white text-sm hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-purple-400 hover:to-pink-400 transition-all duration-300 transform hover:scale-110"
                >
                  Play as Guest
                </button>
              </div>

              {/* Don't show again checkbox */}
              <div className="mt-4 flex items-center justify-center">
                <input
                  id="dont-show"
                  type="checkbox"
                  checked={dontShow}
                  onChange={(e) => setDontShow(e.target.checked)}
                  className="h-4 w-4 rounded border-gray-700 bg-gray-800 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-800 transition-colors duration-200"
                />
                <label htmlFor="dont-show" className="ml-2 text-sm text-gray-400">
                  Don't show this again
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
