import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const EmailVerification = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { verifyEmail } = useAuth();
  const [verificationStatus, setVerificationStatus] = useState('verifying');
  const [message, setMessage] = useState('');
  const [verificationAttempted, setVerificationAttempted] = useState(false);

  useEffect(() => {
    const verify = async () => {
      if (verificationAttempted) return; // Prevent multiple verification attempts
      
      try {
        setVerificationAttempted(true); // Mark verification as attempted
        const decodedToken = decodeURIComponent(token);
        await verifyEmail(decodedToken);
        
        setVerificationStatus('success');
        setMessage('Email verified successfully! Redirecting to dashboard...');
        setTimeout(() => {
          navigate('/dashboard');
        }, 5000);
      } catch (error) {
        console.error('Verification error:', error);
        setVerificationStatus('error');
        setMessage(error.response?.data?.error || 'Email verification failed');
      }
    };

    verify();
  }, [token, navigate, verifyEmail, verificationAttempted]);

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
          Email Verification
        </h2>
        <div className="mt-10 text-center">
          {verificationStatus === 'verifying' && (
            <div className="text-gray-300">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white mx-auto mb-4"></div>
              <p>Verifying your email...</p>
            </div>
          )}
          
          {verificationStatus === 'success' && (
            <div className="p-4 bg-green-500/20 border border-green-500/30 rounded-md">
              <div className="text-green-400 mb-4">
                <svg className="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <p className="text-green-200 mb-4">{message}</p>
            </div>
          )}
          
          {verificationStatus === 'error' && (
            <div className="p-4 bg-red-500/20 border border-red-500/30 rounded-md">
              <div className="text-red-400 mb-4">
                <svg className="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
              <p className="text-red-200 mb-4">{message}</p>
              <Link
                to="/login"
                className="text-indigo-400 hover:text-indigo-300 font-semibold"
              >
                Return to login
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
